/* Import Inter font (modern sans-serif) and Material Icons */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* 
  Fill the entire viewport so we can center content vertically as well as horizontally
*/
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Hide minor horizontal overflow if it occurs */
  background-color: #0d0d0d; /* nearly black background */
  color: #f0f0f0;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

/* 
  Outer container for vertical & horizontal centering.
  If content exceeds the viewport, it scrolls. Otherwise, it's fully centered.
*/
.app-outer {
  min-height: 100vh;
  display: flex;
  justify-content: center;  
  align-items: center;      
  flex-direction: column;
  box-sizing: border-box;
}

/* 
  The main .app container:
  - up to 900px wide
  - leftover horizontal centering is handled by .app-outer
*/
.app {
  max-width: 900px;
  width: 100%;
  padding: 0.5rem;
  position: relative;
  z-index: 0;
}

/* Subtle animated background gradient behind everything */
.app::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(circle at 50% 50%, rgba(0,255,0,0.07), transparent 70%);
  animation: subtleBg 10s ease-in-out infinite alternate;
}
@keyframes subtleBg {
  0% { transform: scale(1); }
  100% { transform: scale(1.02); }
}

/* Header: within .app */
.header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: #0f0f0f;
  padding: 0.5rem 1rem; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  margin-bottom: 0.8rem;
}
.header .header-left {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.4em;
}
.header .header-left .winner-icon {
  color: #00ff00;
  font-size: 1.5rem; /* slightly bigger than text */
}
.header .header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.95rem;
  color: #aaa;
}
.header .header-right a {
  color: #aaa;
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
}
.header .header-right a:hover {
  color: #fff;
}

/* Main content: two columns, smaller spacing. */
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: stretch;
  padding-bottom: 1rem;
}
.left, .right {
  flex: 1 1 0;
  min-width: 280px;
  display: flex;
  flex-direction: column;
}
.left {
  flex: 2 1 0; /* left panel is a bit wider */
}

/* Panels with smaller top/bottom padding, less margin-bottom. */
.panel {
  background: #1a1a1a;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem; 
}

/* .presets-block is also a .panel */
.presets-block > *:last-child {
  margin-bottom: 0 !important;
}

/* 
  Unified heading style for all sections (Inputs, Presets, Results).
*/
.section-title {
  font-size: 1.2em;
  margin: 0 0 0.8rem 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.4em;
}

/* Reorder .presets-block & .results-block on mobile */
.results-block {
  order: 1;
}
@media (max-width: 600px) {
  .presets-block {
    order: 1;
  }
  .results-block {
    order: 0;
  }
}

/* Form fields */
.field {
  margin-bottom: 0.8rem;
}
.field label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 0.95em;
  margin-bottom: 0.3em;
}
.field .material-icons {
  font-size: 18px;
  color: #888;
  cursor: help;
}
.label-with-icon {
  display: inline-flex;
  align-items: center;
  gap: 0.4em;
}

/* Inline error text styling */
.field-error {
  color: #ff5555;
  font-size: 0.85em;
  margin-top: 0.2rem;
  font-style: italic;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 1; 
  background-color: #333;
  color: #fff;
  text-align: left;
  padding: 0.4em 0.6em;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  bottom: 125%;
  right: 0;
  width: max-content;
  max-width: 220px;
  font-size: 0.85em;
  line-height: 1.4em;
  transition: opacity 0.2s;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Inputs, textareas, selects */
input, textarea, select {
  width: 100%;
  background-color: #262626;
  border: none;
  color: #f0f0f0;
  border-radius: 6px;
  padding: 0.4em 0.6em; 
  font-size: 1em;
  box-sizing: border-box;
  transition: box-shadow 0.3s, background-color 0.3s;
}
input::placeholder, textarea::placeholder {
  color: #777;
}
textarea {
  resize: vertical;
}
input:focus, textarea:focus, select:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 255, 0, 0.5);
}

/* Base styling for all buttons, slightly smaller. */
button {
  font-family: 'Inter', sans-serif;
  font-size: 0.9em;
  font-weight: 600;   
  border: none;
  border-radius: 6px; 
  padding: 0.4em 0.6em; 
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
}

/* Primary Button */
.primary-btn {
  background-color: #008800;
  color: #ffffff;
}
.primary-btn:hover {
  background-color: #00aa00;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}
.primary-btn:active {
  background-color: #007500;
}

/* pick-winners-wide modifies the primary-btn with an animated gradient */
.pick-winners-wide {
  width: 100%;
  min-height: 3rem;     
  font-size: 1rem;      
  justify-content: center; 
  align-items: center;
  border-radius: 6px;
  background: linear-gradient(45deg, #008800, #00cc00, #008800);
  background-size: 200% 200%;
  animation: pickWinnersAnim 4s ease-in-out infinite alternate;
}
.pick-winners-wide:hover {
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.4);
}
.pick-winners-wide:active {
  background-position: 70% 50%;
  box-shadow: none;
}
@keyframes pickWinnersAnim {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Muted red for big "clear all" reset button in Presets panel. */
.reset-button {
  width: 2rem;
  height: 2rem;
  justify-content: center;
  border-radius: 6px;
  background-color: #AA4444;
  color: #ffffff;
}
.reset-button:hover {
  background-color: #bb5555;
  box-shadow: 0 0 8px rgba(255, 120, 120, 0.2);
}
.reset-button:active {
  background-color: #993333;
}

/* Secondary button base */
.secondary-btn {
  background-color: #444444;
  color: #ffffff;
}
.secondary-btn:hover {
  background-color: #555555;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.2);
}
.secondary-btn:active {
  background-color: #3a3a3a;
}

/* Raw data only reset button */
.field-reset-btn {
  background-color: #444;
  color: #fff;
  font-size: 0.75em;  
  padding: 0.2em 0.4em; 
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  height: 1.5rem; 
  cursor: pointer;

  /* Pushing to the right side while staying in the same row */
  margin-left: auto; 
}
.field-reset-btn:hover {
  background-color: #555;
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.2);
}
.field-reset-btn:active {
  background-color: #3a3a3a;
}

/* Instead of partial opacity, we use a more muted color for 
   Original Message(s) and Difference(s)
*/
.deprioritized {
  color: #aaa;     
  font-size: 0.9em;
}

/* winners-field ring glow */
.winners-field {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.8rem;
}
.winners-field::before {
  content: "";
  position: absolute;
  top: -4px; left: -4px; 
  right: -4px; bottom: -4px; 
  border-radius: 8px;
  border: 2px solid transparent;
  pointer-events: none; 
  animation: ringPulse 2.5s infinite;
}
@keyframes ringPulse {
  0% {
    box-shadow: 0 0 2px rgba(0,255,0,0.3);
    border-color: rgba(0,255,0,0.3);
  }
  50% {
    box-shadow: 0 0 12px rgba(0,255,0,0.6);
    border-color: rgba(0,255,0,0.6);
  }
  100% {
    box-shadow: 0 0 2px rgba(0,255,0,0.3);
    border-color: rgba(0,255,0,0.3);
  }
}

/* iOS-like toggle styling for toggles */
.ios-toggle-container {
  display: inline-flex;
  align-items: center;
  gap: 0.4em;
}
.ios-toggle {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  border-radius: 11px;
  background-color: #666;
  transition: background-color 0.3s;
  cursor: pointer;
}
.ios-toggle::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #fff;
  transition: transform 0.3s;
}
.ios-toggle.checked {
  background-color: #008800;
}
.ios-toggle.checked::before {
  transform: translateX(16px);
}

/* Smaller, less prominent timestamp */
.timestamp {
  font-size: 0.85em;
  color: #bbb;
  margin: 0.3rem 0 0.6rem 0; 
}

/* 
  Graying out # of Winners & Ties if exactMatch is on
*/
.disabled-section {
  opacity: 0.4;
  pointer-events: none;
}